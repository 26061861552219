<template>
  <div class="modal-body pt-0">
    <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
      <div v-bind:class="{'hidden': !isLoaded }">

        <form 
          ref="componentImportTemplateForm"
          :id="formId" 
          :action="componentImportTemplateUrl" 
          enctype="multipart/form-data"
          accept-charset="UTF-8"
          method="post"
          novalidate="novalidate"
        >
          <input name="utf8" type="hidden" value="✓">
          <input type="hidden" name="authenticity_token" :value="authenticityToken">
          <input type="hidden" name="physical_asset_id" :value="physicalAssetId">

          <template v-if="!currentPhysicalAsset">
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Client Company</label>
                  <v-select
                    placeholder="Select client company"
                    ref="clientCompanies"
                    :class="[{'vs-error': v$.clientCompanyId.$error}]"
                    :options="clientCompanies"
                    label="name"
                    @update:modelValue="setClientCompany"
                  />
                  <div v-if="v$.clientCompanyId.$error" class="error">Client company is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Site</label>
                  <v-select
                    placeholder="Select Site"
                    ref="sites"
                    :class="[{'vs-error': v$.siteId.$error}]"
                    :options="sites"
                    :disabled="!clientCompany"
                    label="name"
                    @update:modelValue="setSite"
                  />
                  <div v-if="v$.siteId.$error" class="error">Site is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Physical Asset</label>
                  <v-select
                    placeholder="Select Physical Asset"
                    ref="physicalAssets"
                    :class="[{'vs-error': v$.physicalAssetId.$error}]"
                    :options="physicalAssets"
                    :disabled="!site"
                    label="name"
                    @update:modelValue="setPhysicalAsset"
                  />
                  <div v-if="v$.physicalAssetId.$error" class="error">Physical Asset is required</div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Site</label>
                  <input 
                    placeholder="Site"
                    class="form-control"
                    type="text"
                    readonly
                    :value="siteName"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Physical Asset</label>
                  <input 
                    placeholder="Physical Asset"
                    class="form-control"
                    type="text"
                    readonly
                    :value="physicalAssetName"
                  />
                </div>
              </div>
            </div>
          </template>

          <div class="row">
            <div class="col-sm-12 col-xs-12" @keydown.enter.prevent>
              <div class="form-group">
                <label class="placeholder placeholder-inactive">Component Types</label>
                <v-select
                  placeholder="Select component type"
                  ref="componentTypes"
                  :class="[{'vs-error': v$.componentTypeId.$error}]"
                  :options="componentTypes"
                  label="name"
                  @update:modelValue="setComponentType"
                />
                <div v-if="v$.componentTypeId.$error" class="error">Component type is required</div>
                <input 
                  type="hidden" 
                  id="component_type_id" 
                  name="component_type_id"
                  :value="componentTypeId"
                />
              </div>
            </div>
          </div>
                
          <div class="form-group">
            <form-button
              ref="submitButton"
              label="Get Component Import Template"
              @submit="submitForm"
            ></form-button>
          </div>

        </form>
      </div>
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import formButton from "../form/form_button";
import formComponentTypes from "../../site_imports/form_component_types";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
  components: {
    formButton,
    vSelect,
    formComponentTypes
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  validations () {
    return {
      clientCompanyId: {
        required
      },
      siteId: {
        required
      },
      physicalAssetId: {
        required
      },
      componentTypeId: {
        required
      }
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      currentPhysicalAsset: null,
      clientCompanies: this.clientCompanies,
      clientCompany: null,
      clientCompanyId: "",
      clientCompanyName: "",
      sites: this.sites,
      site: null,
      siteId: "",
      siteName: "",
      physicalAssets: this.physicalAssets,
      physicalAsset: null,
      physicalAssetId: "",
      physicalAssetName: "",
      componentTypes: this.componentTypes,
      componentType: null,
      componentTypeId: "",
      physicalAssetId: "",
      formId: "",
      componentImportTemplateUrl: "",
    }
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },
 
  mounted: function() {
    this.formId = this.args.componentImportTemplateFormId;
    this.componentImportTemplateUrl = this.args.componentImportTemplateUrl;
    this.physicalAssetId = this.args.physicalAssetId;

    if (this.physicalAssetId) {
      this.fetchPhysicalAsset(); 
    }
    else {
      this.fetchClientCompanies();
    }

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    setClientCompany: function(opt) {
      if (opt) {
        this.clientCompany = opt;
        this.clientCompanyId = opt.id;

        this.fetchSites();
      }
      else {
        this.clientCompany = null;
        this.clientCompanyId = "";

        this.site = null;
        this.siteName = "";
        this.siteId = "";
        this.sites = [];

        this.physicalAsset = null;
        this.physicalAssetId = "";
        this.physicalAssetName = "";
        this.physicalAssets = [];

        this.componentType = null;
        this.componentTypeId = "";
        this.componentTypes = [];
      }

      this.$refs.sites.clearSelection();
      this.$refs.physicalAssets.clearSelection();
      this.$refs.componentTypes.clearSelection();
    },

    fetchClientCompanies: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
            else {
              this.isLoaded = true;
              this.isSpinnerVisible = false;
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },

    setSite: function(opt) {
      if (opt) {
        this.site = opt;
        this.siteId = opt.id;
        this.siteName = opt.name;

        this.fetchPhysicalAssets();
      }
      else {
        this.site = null;
        this.siteId = "";
        this.siteName = "";

        this.physicalAsset = null;
        this.physicalAssetId = "";
        this.physicalAssetName = "";
        this.physicalAssets = [];
        
        this.componentType = null;
        this.componentTypeId = "";
        this.componentTypes = [];
      }

      this.$refs.physicalAssets.clearSelection();
      this.$refs.componentTypes.clearSelection();
    },

    fetchSites: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC",
        },
        filter: {
          clientCompanyId: this.clientCompanyId
        }
      };

      this.sites = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.sites",
          fetchParams,

          (data) => {
            let sites = data.data.sites,
                pageInfo = sites.pageInfo;

            success(sites, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Sites!"
            });
          }
        )
      };

      let getSites = (cursor) => {
        fetch(
          cursor,

          (sites, pageInfo) => {
            sites.edges.forEach((edge) => this.sites.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getSites(pageInfo.endCursor))
            }
          }
        )
      };

      getSites(window.btoa(0));
    },

    setPhysicalAsset: function(opt) {
      if (opt) {
        this.physicalAsset = opt;
        this.physicalAssetId = opt.id;
        this.physicalAssetName = opt.name;

        this.physicalAssetTypeId = opt.physicalAssetType.id;

        this.fetchComponentTypes();
      } 
      else {
        this.physicalAsset = null;
        this.physicalAssetId  = "";
        this.physicalAssetName  =  "";

        this.componentType = null;
        this.componentTypeId = "";
        this.componentTypes = [];
      }

      this.$refs.componentTypes.clearSelection();
    },

    fetchPhysicalAssets: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC",
        },
        filter: {
          siteId: this.site.id
        }
      };

      this.physicalAssets = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.assets",
          fetchParams,

          (data) => {
            let physicalAssets = data.data.physicalAssets,
                pageInfo = physicalAssets.pageInfo;

            success(physicalAssets, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Physical Assets!"
            });
          }
        )
      };

      let getPhysicalAssets = (cursor) => {
        fetch(
          cursor,

          (physicalAssets, pageInfo) => {
            physicalAssets.edges.forEach((edge) => this.physicalAssets.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getPhysicalAssets(pageInfo.endCursor))
            }
          }
        )
      };

      getPhysicalAssets(window.btoa(0));
    },

    fetchPhysicalAsset: function(callback) {
      app.graphql.get("details.asset",
        {
          filter: {
            id: [this.physicalAssetId]
          }
        },
        (data) => {
          let currentPhysicalAsset = data.data.physicalAssets.edges[0].node;

          this.currentPhysicalAsset = currentPhysicalAsset;

          this.physicalAsset = currentPhysicalAsset;
          this.physicalAssetName = currentPhysicalAsset.name;

          this.physicalAssetTypeId = currentPhysicalAsset.physicalAssetType.id;

          this.site = currentPhysicalAsset.site;
          this.siteName = currentPhysicalAsset.site.name;

          this.fetchComponentTypes();
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Physical Asset!"
          });
        }
      );
    },

    setComponentType: function(opt) {
      this.componentType = (opt) ? opt : null;
      this.componentTypeId  = (opt) ? opt.id : "";
      
      this.v$.$reset();
    },

    fetchComponentTypes: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        },
        filter: {
          physicalAssetTypeId: this.physicalAssetTypeId
        }
      };

      this.componentTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.componentTypes",
          fetchParams,

          (data) => {
            let componentTypes = data.data.componentTypes,
                pageInfo = componentTypes.pageInfo;

            success(componentTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Component Types!"
            });
          }
        )
      };

      let getComponentTypes = (cursor) => {
        fetch(
          cursor,

          (componentTypes, pageInfo) => {
            componentTypes.edges.forEach((edge) => this.componentTypes.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getComponentTypes(pageInfo.endCursor))
            }
            else {
              this.isLoaded = true;
              this.isSpinnerVisible = false;
            }
          }
        )
      };

      getComponentTypes(window.btoa(0));
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      if (!this.currentPhysicalAsset) {
        this.v$.clientCompanyId.$touch();
        this.v$.siteId.$touch();
      }

      this.v$.physicalAssetId.$touch();
      this.v$.componentTypeId.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();

        setTimeout(() => {
          this.$refs.submitButton.loadingOff();
        }, 3000);
      }
    }
  }
}

</script>