<template>
  <div class="modal-body pt-0">
    <form v-on:submit.prevent="assignQR">
    <div class="row">
      <div class="col-sm-12">
        <p>Assign QR Label to Site <strong>{{ asset.site.name }}</strong> Asset <strong>{{ asset.name }}</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <input
            ref="txtQrLabelNumber"
            :class="['form-control', {'error': v$.qrLabelNumber.$error}]"
            placeholder="QR Label number" 
            type="text"
            v-model="qrLabelNumber"
          />
          <div v-if="v$.qrLabelNumber.$error" class="error">QR Label number is required</div>
        </div>
      </div>
    </div>
    <div
      v-if="asset.qrLabels.length"
      class="row"
    >
      <div class="col-sm-12">
        <input 
          type="checkbox" 
          v-model="isPrimary" 
          id="qrprimary"
        />
        <label class="checkbox" for="qrprimary">Set as primary QR label</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group-button">
          <button
            type="submit"
            :class="['btn', 'btn-primary', {'is-active': isInProgress}]"
          >Assign QR Label</button>
        </div>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue"

import { useVuelidate } from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';

export default {
  components: {
    
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      asset: {
        site: {},
        qrLabels: []
      },
      qrLabelNumber: '',
      isPrimary: true,
      isInProgress: false,
    }
  },

  validations () {
    return {
      qrLabelNumber: {
        required,
        numeric
      }
    }
  },

  mounted: function() {
    this.asset = this.args.asset;

    this.$refs.txtQrLabelNumber.focus();
  },

  methods: {
    assignQR: function() {
      this.v$.qrLabelNumber.$touch();

      const vm = this;

      if (!vm.v$.$error) {
        vm.isInProgress = true;

        app.graphql.get(
        'select.qr.labels',
          
        {labelNumber: parseInt(vm.qrLabelNumber)},
        
        function(data) {
          if (data.data.qrLabels.edges.length == 0) {
            setTimeout(() => {
              vm.isInProgress = false;
            }, 1000);
            
            app.ui.toast.add({
              priority: "warning",
              title : "QR Label",
              message : "Chosen QR Label does not exist!"
            })

            return
          }
          
          const qrLabel = data.data.qrLabels.edges[0].node
          const qrLabelId = qrLabel.id || null
          const qrLabelAsset = qrLabel.physicalAsset || null

          if (qrLabelAsset) {
            setTimeout(() => {
              vm.isInProgress = false;
            }, 1000);

            app.ui.toast.add({
              priority: "warning",
              title : "QR Label",
              message : "Chosen QR Label is already assigned!"
            })
          }
          else {
            if (qrLabelId) {
              app.graphql.get(
                "update.asset.assignQR",
                {
                  input: {
                    qrLabelId: qrLabelId,
                    physicalAssetId: vm.asset.id,
                    primary: vm.isPrimary
                  }
                },
                function(data) {
                  setTimeout(() => {
                    vm.isInProgress = false;
                  }, 1000);

                  vm.$emit("close")
                  
                  app.ui.toast.add({
                    priority: "success",
                    title : "QR Label",
                    message : "QR Label is successfuly assigned to an asset"
                  })

                  if (vm.args.callback) {
                    vm.args.callback();
                  }
                },
                function(data) {
                  setTimeout(() => {
                    vm.isInProgress = false;
                  }, 1000);

                  vm.$emit("close")

                  if (data.data.assignQrLabel.errors) {
                    app.ui.toast.add({
                      priority: "warning",
                      title : "QR Label",
                      message : data.data.assignQrLabel.errors[0]
                    })
                  }
                }
              )
            }
          }
        },
        function(error) {
          setTimeout(() => {
            vm.isInProgress = false;
          }, 1000);

          app.ui.toast.add({
            priority: "danger",
            title : "Something went wrong!",
            message : "QR Label cannot be assigned!"
          })
        })
      }
    },
  }
}

</script>