<template>
  <div class="modal-body pt-0">
    <component 
      v-bind:is="component"
      :user-can-update="userCanUpdate"
      :args="componentArgs"
    ></component>
  </div>
</template>

<script>
import formUserBasicData from '../../users/form_user_data'
import formUserPassword from '../../users/form_user_password'
import formUserDualAuthentication from '../../users/form_user_dual_auth'

export default {
  components: {
    formUserBasicData,
    formUserPassword,
    formUserDualAuthentication
  },

  props: {
    userCanUpdate: Boolean,
    args: Object
  },

  data() {
    return {
      component: '',
      componentArgs: {}
    }
  },

  created: function() {
    this.component = this.args.component || 'formUserBasicData';

    this.componentArgs = {
      userId: this.args.userId,
      userName: this.args.userName,
      userEmail: this.args.userEmail,
      userPendingActivation: this.args.userPendingActivation,
      userOtpEnabled: this.args.userOtpEnabled,
      authSecretKey: this.args.authSecretKey,
      authQR: this.args.authQR,
      isExoUser: this.args.isExoUser
    }
  }
}

</script>