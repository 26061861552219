<template>
  <div class="modal-body pt-0">
    <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
      <div v-bind:class="{'hidden': !isLoaded }">
        <form 
          id="forgot-password-form" 
          action="/password_resets" 
          enctype="multipart/form-data"
          accept-charset="UTF-8"
          method="post"
          novalidate="novalidate"
        >
          <input name="utf8" type="hidden" value="✓">
          <input type="hidden" name="authenticity_token" :value="authenticityToken">

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <input
                  ref="txtMail"
                  :class="['form-control', {'error': v$.userEmail.$error}]"
                  placeholder="email" 
                  type="text" 
                  name="email" 
                  id="email"
                  v-model.trim="userEmail"
                  @keydown.enter="submitForm"
                />
                <div v-if="v$.userEmail.$error" class="error">Don't forget your email!</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <form-button
                  ref="submitButton"
                  label="Reset my password!"
                  @submit="submitForm"
                ></form-button>
              </div>
            </div>
          </div>

        </form>
      </div>
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import formButton from "../shared/form/form_button";

export default {
  components: {
    formButton
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      userEmail: '',
    }
  },

  validations () {
    return {
      userEmail: {
        required,
        email
      }
    }
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  mounted: function() {
    this.$refs.txtMail.focus();

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    submitForm: function() {
      const form = document.forms['forgot-password-form'];

      this.v$.userEmail.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}
</script>