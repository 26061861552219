<template>
  <form 
      :ref="formId"
      :id="formId" 
      :action="formUrl" 
      enctype="multipart/form-data"
      accept-charset="UTF-8"
      method="post"
      role="form" 
      autocomplete="off"
      novalidate="novalidate"
    >
    <input type="hidden" name="utf8" value="✓">
    <input type="hidden" name="_method" value="patch">
    <input type="hidden" name="authenticity_token" :value="authenticityToken">

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="placeholder placeholder-inactive">User Name</label>
          <input
            name="user[name]"
            id="user_name"
            type="text"
            :class="['form-control', {'error': v$.userName.$error}]"
            placeholder="Name and surname" 
            v-model.trim="userName"
            @keydown.enter.prevent="submitForm"
          >
          <div v-if="v$.userName.$error" class="error">User name is required</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="placeholder placeholder-inactive">Email</label>
          <input
            name="user[email]"
            id="user_email"
            type="text"
            :class="['form-control', {'error': v$.userEmail.$error}]"
            placeholder="user@example.com" 
            v-model.trim="userEmail"
            @keydown.enter.prevent="submitForm"
          >
          <div v-if="v$.userEmail.$error" class="error">Correct email is required</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group-button">
          <form-button
            ref="submitButton"
            label="Update user data"
            class="btn btn-primary"
            @submit="submitForm"
          ></form-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
  components: {
    formButton,
    vSelect
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      userName: '',
      userEmail: '',
    }
  },

  validations () {
    return {
      userName: {
        required
      },
      userEmail: {
        required,
        email
      }
    }
  },

  computed: {
    formId: function() {
      return 'edit_user_' + this.args.userId
    },

    formUrl: function() {
      return '/users/' + this.args.userId
    },

    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  created: function() {
    this.userId = this.args.userId;
    
    this.userName = this.args.userName;
    this.userEmail = this.args.userEmail;
  },

  methods: {
    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.userName.$touch();
      this.v$.userEmail.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}
</script>