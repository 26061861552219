<template>
  <div class="d-flex mb-4">
    <div class="pe-3">1.</div>
    <div>
      You will need an authenticator mobile app to complete this process, such as one of the following:
      <ul class="list-unstyled mt-3">
        <li><a href="https://chromewebstore.google.com/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en" target="_blank">Google Authenticator</a></li>
        <li><a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target="_blank">Microsoft Authenticator</a></li>
        <li><a href="https://www.authy.com/" target="_blank">Authy</a></li>
      </ul>
    </div>
    <div class="ps-5"><img :src="authQRSrc" /></div>
  </div>

  <div class="d-flex mb-4">
    <div class="pe-3">2. </div>
    <div>
      <p>Scan the QR code with your authenticator.</p>
      <p><small class="text-secondary">If you can't scan the code, you can enter this secret key into your authentication app</small></p>
      <p class="h5 d-flex align-items-center" style="text-transform: uppercase;">
        {{ authSecretKey }}
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" @click="copySecretKey" style="margin-left: 10px; cursor: pointer; transform: scale(0.8);">
          <mask id="path-1-inside-1_213_136" fill="white"><rect width="14" height="18" rx="1"/></mask>
          <rect width="14" height="18" rx="1" fill="white" stroke="#C4C4C4" stroke-width="4" mask="url(#path-1-inside-1_213_136)"/>
          <path d="M5 5H17V21H5V5Z" fill="white" stroke="#C4C4C4" stroke-width="2"/>
          <path d="M7 9C7 8.44772 7.44772 8 8 8H14C14.5523 8 15 8.44772 15 9C15 9.55228 14.5523 10 14 10H8C7.44772 10 7 9.55228 7 9Z" fill="#C4C4C4"/>
          <path d="M7 13C7 12.4477 7.44772 12 8 12H14C14.5523 12 15 12.4477 15 13C15 13.5523 14.5523 14 14 14H8C7.44772 14 7 13.5523 7 13Z" fill="#C4C4C4"/>
          <path d="M7 17C7 16.4477 7.44772 16 8 16H14C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18H8C7.44772 18 7 17.5523 7 17Z" fill="#C4C4C4"/>
        </svg>
      </p>
    </div>
  </div>

  <div class="d-flex">
    <div class="pe-3">3.</div>
    <div>
      After scanning the QR code above, enter the six-digit code generated by your authenticator
      <form 
        :ref="formId"
        :id="formId" 
        :action="formUrl" 
        enctype="multipart/form-data"
        accept-charset="UTF-8"
        method="post"
        novalidate="novalidate"
      >
        <input type="hidden" name="utf8" value="✓">
        <input type="hidden" name="_method" value="patch">
        <input type="hidden" name="authenticity_token" :value="authenticityToken">
        <input v-if="isOnboarding" type="hidden" name="step" value="3">
        <input type="hidden" name="user[otp_enabled]" :value="isUserOtpEnabled ? '0' : '1'">
        <div class="row pt-2">
          <div class="col-sm-12" @keydown.enter.prevent>
            <div class="form-group">
              <div :class="['form-control-auth']">
                <input
                  name="user[otp_code_token]"
                  id="user_otp_code_token"
                  type="text"
                  placeholder="000000"
                  class="form-control"
                  @input="handleInput"
                  @keydown="handleKeydown"
                  @keydown.enter="submitForm"
                  @paste.prevent
                  v-model.trim="authToken"
                />
                <svg width="277" height="42" viewBox="0 0 277 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.66667 0.5H31.8889C34.1901 0.5 36.0556 2.36548 36.0556 4.66667V37.3333C36.0556 39.6345 34.1901 41.5 31.8889 41.5H4.66667C2.36548 41.5 0.5 39.6345 0.5 37.3333V4.66667C0.5 2.36548 2.36548 0.5 4.66667 0.5ZM52.8889 0.5H80.1111C82.4123 0.5 84.2778 2.36548 84.2778 4.66667V37.3333C84.2778 39.6345 82.4123 41.5 80.1111 41.5H52.8889C50.5877 41.5 48.7222 39.6345 48.7222 37.3333V4.66667C48.7222 2.36548 50.5877 0.5 52.8889 0.5ZM101.111 0.5H128.333C130.635 0.5 132.5 2.36548 132.5 4.66667V37.3333C132.5 39.6345 130.635 41.5 128.333 41.5H101.111C98.8099 41.5 96.9444 39.6345 96.9444 37.3333V4.66667C96.9444 2.36548 98.8099 0.5 101.111 0.5ZM148.556 0.5H175.778C178.079 0.5 179.944 2.36548 179.944 4.66667V37.3333C179.944 39.6345 178.079 41.5 175.778 41.5H148.556C146.254 41.5 144.389 39.6345 144.389 37.3333V4.66667C144.389 2.36548 146.254 0.5 148.556 0.5ZM196.778 0.5H224C226.301 0.5 228.167 2.36548 228.167 4.66667V37.3333C228.167 39.6345 226.301 41.5 224 41.5H196.778C194.477 41.5 192.611 39.6345 192.611 37.3333V4.66667C192.611 2.36548 194.477 0.5 196.778 0.5ZM245 0.5H272.222C274.523 0.5 276.389 2.36548 276.389 4.66667V37.3333C276.389 39.6345 274.523 41.5 272.222 41.5H245C242.699 41.5 240.833 39.6345 240.833 37.3333V4.66667C240.833 2.36548 242.699 0.5 245 0.5Z" :stroke="v$.authToken.$error ? '#d9534f' : '#ddd'"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <form-button
                ref="submitButton"
                :label="submitButtonLabel"
                class="btn btn-primary"
                @submit="submitForm"
              ></form-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { validationMixin } from "vuelidate";

export default {
  components: {
    formButton,
  },

  props: {
    args: Object
  },
  

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      userId: '',
      authQRSrc: '',
      authSecretKey: '',
      isUserOtpEnabled: false,
      isExoUser: false,
      isOnboarding: false,
      authToken: '',
      authTokenMaxLength: '6',
    }
  },

  validations () {
    return {
      authToken: {
        required
      }
    }
  },

  computed: {
    submitButtonLabel: function() {
      return this.isUserOtpEnabled ? "Verify and Turn off" : "Verify now";
    },

    formId: function() {
      return 'edit_user_' + this.userId
    },

    formUrl: function() {
      return '/users/' + this.userId
    },

    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  created: function() {
    this.userId = this.args.userId;

    this.isExoUser = this.args.isExoUser;
    
    this.isUserOtpEnabled = this.args.userOtpEnabled;

    this.isOnboarding = this.args.onboarding;
    
    this.authSecretKey = this.args.authSecretKey;
    this.authQRSrc = 'data:image/svg+xml;base64,' + this.args.authQR;
  },

  methods: {
    copySecretKey: function() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.authSecretKey);

        app.ui.toast.add({
          priority: "success",
          message: "Secret key copied!"
        });
      }
    },

    handleKeydown(event) {
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
        "Enter"
      ];

      // Allow control keys
      if (allowedKeys.includes(event.key)) {
        return;
      }

      // Prevent further input if maxlength is reached
      if (this.authToken.length >= this.authTokenMaxLength) {
        event.preventDefault();
      }

      // Only digit keys
      if (!/^[0-9]$/.test(event.key)) {
        event.preventDefault();
      }
    },

    handleInput(event) {
      // Enforce maxlength on pasting or other input methods
      if (this.authToken.length > this.authTokenMaxLength) {
        this.authToken = this.authToken.slice(0, this.authTokenMaxLength);
      }
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.authToken.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>
