<template>
<div class="modal-body">
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div v-bind:class="{'hidden': !isLoaded }">

      <div :class="['modal-user-onboarding', stepClass]">
        <div class="onboarding-flow-steps">
          <h4>User Onboarding</h4>
          <ul>
            <li><span>Welcome</span></li>
            <li><span>Set Password</span></li>
            <li><span>Enable 2FA</span></li>
            <li><span>All set</span></li>
          </ul>
        </div>
        <div class="onboarding-flow-screens">
          <div class="flow-screen screen-1 screen-welcome">
            <div>
              <h3 class="fw-normal mb-0">Welcome {{ userPendingActivation ? 'to Exo Link!' : 'back!' }}</h3>
              <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.7);">
                <path d="M72 120C59.2288 111.032 48.7533 99.0919 41.4473 85.1757C34.1412 71.2594 30.2164 55.7702 30 40L72 24L114 40C113.784 55.7702 109.859 71.2594 102.553 85.1757C95.2467 99.0919 84.7712 111.032 72 120Z" stroke="#E67823" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M66.5049 80V77.1699L70.166 76.541V59.1787L66.5049 58.5498V55.6973H74.5908V76.541L78.252 77.1699V80H66.5049ZM69.9414 49.7002V44.9609H74.5908V49.7002H69.9414Z" fill="#E67823"/>
              </svg>
              <template v-if="!userPendingActivation && isExoUser">
                <p>We’re excited to have you on the EXO team!<br/>As part of EXO policy, accounts should be secured with Two-Factor Authentication.<br/>Enable it in seconds to protect yours today</p>
                <button class="btn btn-inverse-primary btn-sm" @click="showScreen(3)">Set Dual Authentication</button>
              </template>
              <template v-else>
                <p>We’re excited to have you on board.<br/>Let’s get started by setting up your account securely.</p>
                <button class="btn btn-inverse-primary btn-sm" @click="showScreen(2)">Set Your Password</button>
              </template>
            </div>
          </div>
          <div class="flow-screen screen-2 screen-password">
            <div>
              <p>Choose a password to setup your account</p>
              <form-user-password
                v-if="onboardingStep == 2"
                :user-can-update="isExoUser"
                :args="componentArgs"
              />
            </div>
          </div>
          <div class="flow-screen screen-3 screen-2fa">
            <div :class="['d-flex', 'flex-column', 'justify-content-center', {'justify-content-between': !isExoUser}]">
              <div>
                <form-user-dual-authentication
                  v-if="onboardingStep == 3"
                  :user-can-update="isExoUser"
                  :args="componentArgs"
                />
              </div>
              <div 
                v-if="!isExoUser"
                class="align-self-end">
                <button class="btn btn-inverse-primary" @click="showScreen(4)">Do it later</button>
              </div>
            </div>
          </div>
          <div class="flow-screen screen-4 screen-allset">
            <div>
              <h3 class="fw-normal mb-0">You’re All Set!</h3>
              <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.7);">
                <path d="M72 120C59.2288 111.032 48.7533 99.0919 41.4473 85.1757C34.1412 71.2594 30.2164 55.7702 30 40L72 24L114 40C113.784 55.7702 109.859 71.2594 102.553 85.1757C95.2467 99.0919 84.7712 111.032 72 120Z" fill="white"/>
                <path d="M57 69L66 78L90 57M72 120C59.2288 111.032 48.7533 99.0919 41.4473 85.1757C34.1412 71.2594 30.2164 55.7702 30 40L72 24L114 40C113.784 55.7702 109.859 71.2594 102.553 85.1757C95.2467 99.0919 84.7712 111.032 72 120Z" stroke="#E67823" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <p>Your account is ready, and all essential steps are complete.<br/>You’re now fully equipped to manage facilities with confidence.</p>
              <a href="/client_companies" class="btn btn-primary mt-2">Get Started</a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="spinner"></div>
  </div>
</div>
</template>

<script>
import formUserPassword from '../../users/form_user_password'
import formUserDualAuthentication from '../../users/form_user_dual_auth'

export default {
  components: {
    formUserPassword,
    formUserDualAuthentication
  },

  props: {
    args: Object
  },

  data() {
    return {
      componentArgs: {},
      onboardingStep: '1',
      userPendingActivation: false,
      userOtpEnabled: false,
      isExoUser: false,
      isLoaded: false,
      isSpinnerVisible: true,
    }
  },

  created: function() {
    this.onboardingStep = this.args.step;

    this.userPendingActivation = this.args.userPendingActivation ? JSON.parse(this.args.userPendingActivation) : false;
    this.userOtpEnabled = this.args.userOtpEnabled ? JSON.parse(this.args.userOtpEnabled) : false;
    this.isExoUser = this.args.isExoUser ? JSON.parse(this.args.isExoUser) : false;

    this.componentArgs = {
      userId: this.args.userId,
      userName: this.args.userName,
      userEmail: this.args.userEmail,
      userPendingActivation: this.userPendingActivation,
      userOtpEnabled: this.userOtpEnabled,
      authSecretKey: this.args.authSecretKey,
      authQR: this.args.authQR,
      isExoUser: this.isExoUser,
      onboarding: true,
    }
  },

  mounted: function() {
    this.isLoaded = true

    setTimeout(() => {
      this.isSpinnerVisible = false
    }, 1500);
  },

  computed: {
    stepClass: function() {
      return 'step-' + this.onboardingStep
    }
  },

  methods: {
    showScreen: function(screen) {
      this.isSpinnerVisible = true

      this.onboardingStep = screen

      setTimeout(() => {
        this.isSpinnerVisible = false
      }, 500);
    }
  }
}

</script>
