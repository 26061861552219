<template>
  <div class="modal-body pt-0">
    <form @submit.prevent="generateReport">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Report Name</label>
            <input
              name="report[name]"
              id="report_name"
              ref="reportName"
              type="text"
              :class="['form-control', {'error': v$.reportName.$error}]"
              placeholder="Name" 
              v-model.trim="reportName"
              @keydown.enter.prevent="generateReport"
            >
            <div v-if="v$.reportName.$error" class="error">Report name is required</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <form-button
              ref="submitButton"
              label="Export Finindgs"
              class="btn btn-primary"
              @submit="generateReport"
            ></form-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Filter from '../filter/findings_filter_core';

import formButton from '../form/form_button';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  extends: Filter,

  components: {
    formButton,
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data: function () {
    return {
      reportName: '',
    };
  },

  validations () {
    return {
      reportName: {
        required
      }
    }
  },

  mounted: function() {
    this.$refs.reportName.focus();
  },

  methods: {  
    generateReport: function () {
      this.v$.reportName.$touch();

      if (!this.v$.$error) {
        const args = this.getFilterState();

        this.$refs.submitButton.loadingOn();

        app.graphql.get(
          "create.downloadableReport",

          {
            input: {
              name: this.reportName,
              reportType: "FindingExportCSV",
              arguments: args,
            }
          },

          (data) => {
            const response = data.data.createDownloadableReport;

            if (response.errors) {
              response.errors.forEach((error) => {
                app.ui.toast.add({
                  priority: "warning",
                  title: "Unable to create report",
                  message: error
                });
              });

              this.$refs.submitButton.loadingOff();
            } else {
              window.location.href = "/downloadable_reports";
            }
          }
        );
      }
    }
  }
};
</script>