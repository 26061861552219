<template>
  <form 
      :ref="formId"
      :id="formId" 
      :action="formUrl" 
      enctype="multipart/form-data"
      accept-charset="UTF-8"
      method="post"
      role="form" 
      autocomplete="off"
      novalidate="novalidate"
    >
    <input type="hidden" name="utf8" value="✓">
    <input type="hidden" name="_method" value="patch">
    <input type="hidden" name="authenticity_token" :value="authenticityToken">
    <input v-if="isOnboarding" type="hidden" name="step" value="2">

    <div class="row">
      <div v-if="!isPendingUser" class="col-sm-12">
        <div class="form-group">
          <label class="placeholder placeholder-inactive" for="user_current_password">Current password</label>
          <input
            name="user[current_password]"
            id="user_current_password"
            type="password"
            :class="['form-control', {'error': v$.currentPassword.$error}]"
            placeholder="Password"
            autocomplete="off"
            v-model.trim="currentPassword"
            @keydown.enter.prevent="submitForm"
          >
          <div v-if="v$.currentPassword.$error" class="error">Current password is required</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="placeholder placeholder-inactive" for="user_password">{{ !isPendingUser ? 'Confirm new password' : 'Password' }}</label>
          <input
            name="user[password]"
            id="user_password"
            type="password"
            :class="['form-control', {'error': v$.newPassword.$error}]"
            placeholder="Must be at least 3 characters"
            autocomplete="off"
            v-model.trim="newPassword"
            @keydown.enter.prevent="submitForm"
          >
          <div v-if="v$.newPassword.$error" class="error">Minimal 3 character length password is required</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="placeholder placeholder-inactive" for="user_password_confirmation">{{ !isPendingUser ? 'Confirm new password' : 'Confirm password' }}</label>
          <input
            name="user[password_confirmation]"
            id="user_password_confirmation"
            type="password"
            :class="['form-control', {'error': v$.confirmPassword.$error}]"
            placeholder="Plaease confirm password"
            autocomplete="off"
            v-model.trim="confirmPassword"
            @keydown.enter.prevent="submitForm"
          >
          <div v-if="v$.confirmPassword.$error" class="error">Password should be equal with new password</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group-button">
          <form-button
            ref="submitButton"
            label="Set password"
            class="btn btn-primary"
            @submit="submitForm"
          ></form-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';

export default {
  components: {
    formButton,
    vSelect
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      userId: '',
      isPendingUser: false,
      isOnboarding: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  },

  validations () {
    return {
      currentPassword: {
        required
      },
      newPassword: {
        required,
        minLength: minLength(3)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(this.newPassword)
      }
    }
  },

  computed: {
    formId: function() {
      return 'edit_user_' + this.args.userId
    },

    formUrl: function() {
      return '/users/' + this.args.userId
    },

    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  created: function() {
    this.userId = this.args.userId;
    
    this.isPendingUser = this.args.userPendingActivation ? JSON.parse(this.args.userPendingActivation) : false;
    this.isOnboarding = this.args.onboarding;
  },

  methods: {
    submitForm: function() {
      const form = document.forms[this.formId];

      if (!this.isPendingUser) {
        this.v$.currentPassword.$touch();
      }
      
      this.v$.newPassword.$touch();
      this.v$.confirmPassword.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>
