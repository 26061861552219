<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-12">
          <fieldset>
            <template v-if="!formStep || formStep == 1">
              <div class="row pt-3">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="email" class="placeholder">Email</label>
                    <input 
                      :class="['form-control', 'form-control-email', {'error': v$.userEmail.$error}]"
                      placeholder="Enter your email" 
                      type="text" 
                      name="email" 
                      id="email"
                      autofocus="false"
                      v-model.trim="userEmail"
                      @keydown.enter="submitForm"
                    />
                    <div v-if="v$.userEmail.$error" class="error">Don't forget to login with your email</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="password" class="placeholder">Password</label>
                    <input 
                      :class="['form-control', 'form-control-password', {'error': v$.userPassword.$error}]"
                      placeholder="Enter your password" 
                      type="password" 
                      name="password" 
                      id="password"
                      autofocus="false"
                      autocomplete="off"
                      v-model.trim="userPassword"
                      @keydown.enter="submitForm"
                    />
                    <div v-if="v$.userPassword.$error" class="error">Check your password</div>
                  </div>
                </div>
              </div>
              <div class="row pb-4">
                <div class="col-12 text-end">
                  <a 
                    href="/password_resets/new"
                    @click.prevent="showForgottenPasswordModal"
                  >Forgot Password?</a>
                </div>
              </div>
            </template>
            <template v-if="!formStep || formStep == 2">
            <div class="row py-4">
              <div class="col-sm-12">
                <div class="form-group">
                  <div class="form-control-auth">
                    <input
                      name="otp_code_token"
                      id="otp_code_token"
                      type="text"
                      placeholder="000000"
                      class="form-control"
                      @input="handleInput"
                      @keydown="handleKeydown"
                      @keydown.enter="submitForm"
                      @paste.prevent
                      v-model.trim="authToken"
                    />
                    <svg width="277" height="42" viewBox="0 0 277 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.66667 0.5H31.8889C34.1901 0.5 36.0556 2.36548 36.0556 4.66667V37.3333C36.0556 39.6345 34.1901 41.5 31.8889 41.5H4.66667C2.36548 41.5 0.5 39.6345 0.5 37.3333V4.66667C0.5 2.36548 2.36548 0.5 4.66667 0.5ZM52.8889 0.5H80.1111C82.4123 0.5 84.2778 2.36548 84.2778 4.66667V37.3333C84.2778 39.6345 82.4123 41.5 80.1111 41.5H52.8889C50.5877 41.5 48.7222 39.6345 48.7222 37.3333V4.66667C48.7222 2.36548 50.5877 0.5 52.8889 0.5ZM101.111 0.5H128.333C130.635 0.5 132.5 2.36548 132.5 4.66667V37.3333C132.5 39.6345 130.635 41.5 128.333 41.5H101.111C98.8099 41.5 96.9444 39.6345 96.9444 37.3333V4.66667C96.9444 2.36548 98.8099 0.5 101.111 0.5ZM148.556 0.5H175.778C178.079 0.5 179.944 2.36548 179.944 4.66667V37.3333C179.944 39.6345 178.079 41.5 175.778 41.5H148.556C146.254 41.5 144.389 39.6345 144.389 37.3333V4.66667C144.389 2.36548 146.254 0.5 148.556 0.5ZM196.778 0.5H224C226.301 0.5 228.167 2.36548 228.167 4.66667V37.3333C228.167 39.6345 226.301 41.5 224 41.5H196.778C194.477 41.5 192.611 39.6345 192.611 37.3333V4.66667C192.611 2.36548 194.477 0.5 196.778 0.5ZM245 0.5H272.222C274.523 0.5 276.389 2.36548 276.389 4.66667V37.3333C276.389 39.6345 274.523 41.5 272.222 41.5H245C242.699 41.5 240.833 39.6345 240.833 37.3333V4.66667C240.833 2.36548 242.699 0.5 245 0.5Z" :stroke="v$.authToken.$error ? '#d9534f' : '#ddd'"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            </template>
          </fieldset>
          <footer>
            <div class="d-grid">
              <form-button
                ref="submitButton"
                class="btn justify-content-center"
                :label="submitButtonLabel"
                @submit="submitForm"
              ></form-button>
            </div>
          </footer>
        </div>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import formButton from "../shared/form/form_button";

export default {
  components: {
    formButton
  },

  props: {
    formId: {
      type: String
    },
    formStep: {
      type: Number
    }
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      userEmail: '',
      userPassword: '',
      authToken: '',
      isLoaded: false,
      isSpinnerVisible: true,
      isOtpInfoPresented: false,
    }
  },

  validations () {
    return {
      userEmail: {
        required,
        email
      },
      userPassword: {
        required
      },
      authToken: {
        required
      }
    }
  },

  computed: {
    submitButtonLabel: function() {      
      return (!this.formStep || this.formStep == 1) ? "Login" : "Verify"
    }
  },

  mounted: function() {
    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    showForgottenPasswordModal: function() {
      vmModal.$refs.modal.show({
        template: 'forgotPasswordModal',
        title: 'Forgotten password?',
        style: {
          size: 'md',
          verticalyCentered: true
        }
      })
    },

    handleKeydown(event) {
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
        "Enter"
      ];

      // Allow control keys
      if (allowedKeys.includes(event.key)) {
        return;
      }

      // Prevent further input if maxlength is reached
      if (this.authToken.length >= this.authTokenMaxLength) {
        event.preventDefault();
      }

      // Only digit keys
      if (!/^[0-9]$/.test(event.key)) {
        event.preventDefault();
      }
    },

    handleInput(event) {
      // Enforce maxlength on pasting or other input methods
      if (this.authToken.length > this.authTokenMaxLength) {
        this.authToken = this.authToken.slice(0, this.authTokenMaxLength);
      }
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.userEmail.$touch();
      this.v$.userPassword.$touch();
      this.v$.authToken.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}
</script>
