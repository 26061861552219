<template>
  <div class="modal-body pt-0">
    <form
      :id="formId"
      :action="formUrl" 
      accept-charset="UTF-8" 
      method="post" 
      novalidate="novalidate"
    >
      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="authenticity_token" :value="authenticityToken">

      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label class="placeholder placeholder-inactive" for="qr_label_batch_label_count">Batch size</label>
            <input
              :class="['form-control', {'error': v$.batchSize.$error}]"
              placeholder="Batch size"
              type="text"
              name="qr_label_batch[label_count]"
              id="qr_label_batch_label_count"
              v-model.trim="batchSize"
            />
            <div v-if="v$.batchSize.$error" class="error">Correct batch size is required</div>
          </div>
        </div>
      </div>
      <div class="row" @keydown.enter.prevent>
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static" for="qr_label_batch_notes">Notes</label>
            <textarea 
              rows="4" 
              class="form-control" 
              placeholder="Notes" 
              name="qr_label_batch[notes]"
              id="qr_label_batch_notes"
              v-model.trim="batchNotes"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-group-button">
        <form-button
          ref="submitButton"
          label="Generate Batch"
          @submit="submitForm"
        ></form-button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import formButton from "../form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required, integer } from '@vuelidate/validators';


export default {
  components: {
    formButton
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      formId: "",
      formUrl: "",
      batchSize: "",
      batchNotes: ""
    }
  },

  validations () {
    return {
      batchSize: {
        required,
        integer
      }
    }
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  mounted: function() {
    this.formId = this.args.newQRLabelBatchFormId
    this.formUrl = this.args.qRLabelBatchUrl
  },

  methods: {
    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.batchSize.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>