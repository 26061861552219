
<template>
  <div class="modal-body pt-0">
    <inspector-form
      ref="form"
      :currentInspectorId="id"
      :currentInspectorName="name"
    ></inspector-form>
  </div>
</template>

<script>
import Vue from "vue";
import InspectorForm from '../../inspectors/form';

export default {
  components: {
    InspectorForm
  },

  props: {
    args: Object
  },

  data() {
    return {
      id: String,
      name: String
    }
  },

  created: function() {
    if (this.args) {
      this.id = this.args.id;
      this.name = this.args.name;
    }
  }
}

</script>